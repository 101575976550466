import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import EditorWrapper from "../../components/editor/wrapper";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function EmailUsers() {
  const [activity, setActivity] = useState(null);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
  });

  const emailDistribution = watch("emailDistribution");

  async function onSubmit(data) {
    try {
      console.log(`----- Submit`);

      if (
        !(
          typeof data.emailTitle === "string" &&
          typeof data.emailBody === "string" &&
          data.emailTitle.length > 3 &&
          data.emailBody.length > 3
        )
      ) {
        alert(`Invalid fields, specify a title and body with 3+ characters.`);
        return false;
      }

      const sectionResponse = await axios.post(`${serverUrl}/admin/email`, data);

      if (sectionResponse && sectionResponse.data && sectionResponse.data.success) {
        console.log(`----- Sent email`);
        navigate(-1);
      } else {
        alert(`Could not email.`);
      }
    } catch (err) {
      console.error(`ERR: Could update activity!`);
    }
  }

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row className={"mb-5"}>
        <Col lg={8} xl={6}>
          <h1 className={"mb-3"}>Email All Users</h1>

          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <select
              {...register("emailDistribution")}
              default="test"
              style={{ marginBottom: 15 }}
              className="form-control"
            >
              <option value="test">TEST ONLY</option>
              <option value="premium">SEND TO PREMIUM</option>
              <option value="all">SEND TO ALL</option>
            </select>

            <input
              placeholder={"Email subject"}
              {...register("emailSubject", { required: true, minLength: 3, maxLength: 100 })}
              className="form-control"
              style={{ marginBottom: 15 }}
            />
            <input
              placeholder={"Email title"}
              {...register("emailTitle", { required: false, minLength: 3, maxLength: 100 })}
              className="form-control"
              style={{ marginBottom: 15 }}
            />
            <textarea
              placeholder={`This is the body of the email, enter text here.`}
              {...register("emailBody", { required: true, minLength: 3, maxLength: 10000 })}
              className="form-control"
              rows="10"
            />

            {emailDistribution === "all" || emailDistribution === "premium" ? (
              <div
                style={{
                  backgroundColor: "orange",
                  padding: 15,
                  textAlign: "center",
                  marginTop: 10,
                  marginBottom: 10,
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                REAL EMAIL: This will email all users in the selected group.
              </div>
            ) : null}

            <div className="mt-2">
              <input
                className="form-control"
                type="submit"
                value="SEND"
                disabled={!isDirty || !isValid}
              />
            </div>
          </form>
        </Col>
      </Row>
    </EditorWrapper>
  );
}

export default EmailUsers;
