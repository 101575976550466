import "./App.scss";
import "./styles/global.scss";
import "./styles/courseApp.scss";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Home from "./screens/home";
import Partner from "./screens/partner";
import Landing from "./screens/landing";
import Privacy from "./screens/privacy";
import Terms from "./screens/terms";
import About from "./screens/about";
import Deletion from "./screens/deletion";
import { HashRouter, Routes, Route } from "react-router-dom";
import Chart from "./screens/chart";
import Login from "./screens/shared/login";
import SectionEditor from "./screens/editor/sectionEditor";
import CourseEditor from "./screens/editor/courseEditor";
import ActivityEditor from "./screens/editor/activityEditor";
import Gate from "./screens/gate";
import ActivityItemEditor from "./screens/editor/activityItemEditor";
import Report from "./screens/report";
import Blog from "./screens/blog";
import Images from "./screens/editor/images";
import Deeplink from "./screens/deeplink";
import AdminOverview from "./screens/editor/adminOverview";
import ActivityContentGate from "./screens/activityContentGate";
import EmailUsers from "./screens/editor/emailUsers";
import Unsubscribed from "./screens/unsubscribed";
import AssessmentEditor from "./screens/editor/assessmentEditor";
import AssessmentItemEditor from "./screens/editor/assessmentItemEditor";
import CourseHome from "./screens/course/courseHome";
import Logs from "./screens/editor/logs";
import CourseSettings from "./screens/course/settings";
import { useSelector, useDispatch } from "react-redux";
import { serverUrl } from "./screens/utility";
import { logout, setStaffUser, setUser } from "./store/user";
import { setCourseSections } from "./store/course";
import { setOrganisation } from "././store/organisation";
import Education from "./screens/education";
import SignUpPartner from "./screens/shared/signUpPartner";
import PartnerHome from "./screens/partner/partnerHome";
import PartnerReferral from "./screens/partner/partnerReferral";
import { setReferrals, setPartner } from "./store/partner";
import PartnerSettings from "./screens/partner/partnerSettings";
import AdminPartners from "./screens/editor/adminPartners";
import PartnerReport from "./screens/partner/partnerReport";
import PartnerTerms from "./screens/partner-terms";
import Event from "./screens/event";
import EventEditor from "./screens/editor/eventEditor";
axios.defaults.withCredentials = true;
axios.defaults.timeout = 2000;

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userStore.user);
  const notLoggedIn = useSelector((state) => state.userStore.notLoggedIn);
  const token = localStorage.getItem("token");
  const STATUS = {
    NONE: "none",
    LOADING: "loading",
    SUCCESS: "success",
    ERROR: "error",
  };
  const [status, setStatus] = useState(STATUS.NONE);
  const COURSE_PREFIX = `/course/`;
  const ADMIN_PREFIX = `/admin/`;
  const PARTNER_PREFIX = `/partner/`;

  async function loginViaToken(token) {
    try {
      console.log(`loginViaToken...`);

      axios
        .get(`${serverUrl}/login/token?token=${encodeURIComponent(token)}&staffCheck=true`)
        .then(async (response) => {
          // Persist to redux store, redirection then handled by componentDidUpdate (redux store update!)
          if (response && response.data && response.data.success) {
            const { user, token, isStaffUser } = response.data;
            console.log(`--- User is: ${JSON.stringify(user)}`);
            localStorage.setItem("token", token);
            dispatch(setUser(user));
            console.log(`STAFF USER IS: ${isStaffUser}`);
            dispatch(setStaffUser(isStaffUser));
          } else {
            console.info(`ERR: Could not login. ${JSON.stringify(response.data)}`);
            dispatch(logout());
          }
        })
        .catch((error) => {
          console.error(`Unable to login, server error. ${JSON.stringify(error)}`);
          dispatch(logout());
        });
    } catch (err) {
      console.error(`ERR: User login error. ${err}`);
    }
  }

  async function loadCourse() {
    try {
      console.log(`loadCourse...`);

      axios
        .get(`${serverUrl}/course/overview`)
        .then(async (response) => {
          // Persist to redux store, redirection then handled by componentDidUpdate (redux store update!)
          if (response && response.data) {
            const { sections, courseMeta, nextSection } = response.data;
            console.log(`--- sections is: ${JSON.stringify(sections)}`);

            dispatch(setCourseSections(sections));
            setStatus(STATUS.SUCCESS);
          } else {
            console.info(`ERR: Could not fetch course. ${JSON.stringify(response)}`);
          }
        })
        .catch((error) => {
          console.error(`Unable to fetch course, server error. ${JSON.stringify(error)}`);
        });
    } catch (err) {
      console.error(`ERR: Fetch course error. ${err}`);
    }
  }

  async function loadOrganisation() {
    try {
      console.log(`loadOrganisation...`);

      axios
        .get(`${serverUrl}/organisation`)
        .then(async (response) => {
          // Persist to redux store, redirection then handled by componentDidUpdate (redux store update!)
          if (response && response.data) {
            const { organisation } = response.data;
            console.log(`--- organisation is: ${JSON.stringify(organisation)}`);

            dispatch(setOrganisation(organisation));
            setStatus(STATUS.SUCCESS);
          } else {
            console.info(`ERR: Could not fetch org. ${JSON.stringify(response)}`);
          }
        })
        .catch((error) => {
          console.error(`Unable to fetch org, server error. ${JSON.stringify(error)}`);
        });
    } catch (err) {
      console.error(`ERR: User login error. ${err}`);
    }
  }

  async function getPartnerData() {
    console.log(`Fetching referrals`);
    const referralsResponse = await axios.get(`${serverUrl}/referral`);
    if (referralsResponse.data && referralsResponse.data.referredUsers) {
      const referrals = referralsResponse.data.referredUsers;
      dispatch(setReferrals(referrals));
    }

    console.log(`Fetching partner data`);
    const partnerResponse = await axios.get(`${serverUrl}/partner`);
    if (partnerResponse.data && partnerResponse.data.partner) {
      const partner = partnerResponse.data.partner;
      dispatch(setPartner(partner));
    }

    setStatus(STATUS.SUCCESS);
  }

  // Check token and re-login
  useEffect(() => {
    const path = window.location.href;

    if (path?.includes("/login")) {
      setStatus(STATUS.NONE);
    }

    if (
      path?.includes(COURSE_PREFIX) ||
      path?.includes(ADMIN_PREFIX) ||
      path?.includes(PARTNER_PREFIX)
    ) {
      console.log(`----- Authenticated route detected`);

      if (!user && token) {
        loginViaToken(token);
      }

      console.log(`Status: ${status}`);
      if (status !== STATUS.SUCCESS) {
        setStatus(STATUS.LOADING);

        if (user) {
          // Logged in user, load related data
          loadOrganisation();
          loadCourse();
        }

        // PARTNER ONLY
        if (path?.includes(PARTNER_PREFIX) && user && user.partnerId) {
          getPartnerData();
        }
      }
    }
  }, [user, status, window.location?.pathname]);

  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/speech-and-language-therapists" element={<Partner />} />
          <Route path="/slt" element={<Partner />} />
          <Route path="/early-years" element={<Education />} />
          <Route path="/welcome" element={<Landing />} />
          <Route path="/deletion" element={<Deletion />} />
          <Route path="/content" element={<Gate />} />
          <Route path="/activity" element={<ActivityContentGate />} />
          <Route path="/deeplink" element={<Deeplink />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/partner-terms" element={<PartnerTerms />} />
          <Route path="/about" element={<About />} />
          <Route path="/unsubscribed" element={<Unsubscribed />} />
          <Route path="/event" element={<Event />} />

          {/* Mobile App */}
          <Route path="/chart" element={<Chart />} />
          <Route path="/report" element={<Report />} />
          <Route path="/blog" element={<Blog />} />

          {/* Web App [Unauthenticated or Authenticated] */}
          <Route path="/login" element={<Login />} />
          <Route path="/partner-sign-up" element={<SignUpPartner />} />

          {/* Web App [Authenticated] */}
          {user && user._id ? (
            <>
              {/* Web Course App. MUST contain the prefix /course/ */}
              <Route path="/course/home" element={<CourseHome />} />
              <Route path="/course/settings" element={<CourseSettings />} />

              {/* Web Editor App */}
              <Route path="/admin/home" element={<AdminOverview />} />
              <Route path="/admin/sectionEditor" element={<SectionEditor />} />
              <Route path="/admin/courseEditor" element={<CourseEditor />} />
              <Route path="/admin/activityEditor" element={<ActivityEditor />} />
              <Route path="/admin/activityItemEditor" element={<ActivityItemEditor />} />
              <Route path="/admin/images" element={<Images />} />
              <Route path="/admin/overview" element={<AdminOverview />} />
              <Route path="/admin/email" element={<EmailUsers />} />
              <Route path="/admin/assessment" element={<AssessmentEditor />} />
              <Route path="/admin/assessmentItemEditor" element={<AssessmentItemEditor />} />
              <Route path="/admin/logs" element={<Logs />} />
              <Route path="/admin/partners" element={<AdminPartners />} />
              <Route path="/admin/event" element={<EventEditor />} />

              <Route path="/partner/home" element={<PartnerHome />} />
              <Route path="/partner/link" element={<PartnerReferral />} />
              <Route path="/partner/settings" element={<PartnerSettings />} />
              <Route path="/partner/report" element={<PartnerReport />} />
            </>
          ) : (
            <>
              <Route path="/course/*" element={<Login />} />
              <Route path="/admin/*" element={<Login />} />
              <Route path="/partner/*" element={<Login />} />
            </>
          )}
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
