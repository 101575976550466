import "../styles/global.scss";
import mockup from "../images/pippinmockup1.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import learn from "../images/learn.png";
import React, { useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import { useForm } from "react-hook-form";
import pippinLogo from "../images/pippin-logo-parrot.png";
import { serverUrl } from "./utility";

function Landing() {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);

    axios.post(`${serverUrl}/user/waitlist`, {
      ...data,
      referrer: document.referrer,
    });

    setSubmitted(true);
  };

  return (
    <div className="Landing">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ position: "relative" }}>
            <img src={mockup} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0 }}>
            Helping Your Child Find Their Voice
          </h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 15 }}>
            Strategies, activities and resources created with speech therapists.
          </h2>

          {submitted !== true ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 15,
                paddingBottom: 30,
              }}
            >
              <div style={{ marginRight: 10 }}>
                <input
                  type="text"
                  placeholder="Email address"
                  {...register("email", { required: true })}
                />
                {errors.email && <p>Email is required.</p>}
              </div>

              <div>
                <input type="submit" value="NOTIFY ME" />
              </div>
            </form>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 15,
                paddingBottom: 30,
                color: "#ffffff",
              }}
            >
              Thanks, we are launching soon and you'll get free early access!
              <br />
              Keep an eye out for our email updates.
            </div>
          )}
        </div>
      </div>

      <div className={styles.benefits}>
        <div className={styles.benefitItem}>
          <div className={styles.benefitItemImage}>
            <img src={learn} width={80} className={styles.benefitItemImageSrc} />
          </div>

          <div className={styles.benefitItemText}>
            1. Speech therapy training for parents to use anywhere.
          </div>
        </div>

        <div className={styles.benefitItem}>
          <div className={styles.benefitItemImage}>
            <img src={chat} width={80} className={styles.benefitItemImageSrc} />
          </div>

          <div className={styles.benefitItemText}>
            2. Includes easy to set-up games and activities to encourage speech
          </div>
        </div>

        <div className={styles.benefitItem}>
          <div className={styles.benefitItemImage}>
            <img src={progress} width={80} className={styles.benefitItemImageSrc} />
          </div>

          <div className={styles.benefitItemText}>
            3. Review speech progress and track words on the go
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
