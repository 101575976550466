import styles from "./footer.module.scss";

// A "Footer" Row
export default function Footer(props) {
  return (
    <section className={styles.footer}>
      <div>
        <p className={`h4 ${styles.h4}`} style={{ fontWeight: "bold" }}>
          Family Timeline Ltd.
        </p>
        <p>
          Pippin is a trading name of Family Timeline Ltd.
          <br />
          UK Company number 12763607.
        </p>
      </div>

      <div className={styles.footerSection}>
        <p className={`h4 ${styles.h4}`} style={{ fontWeight: "bold" }}>
          Solutions
        </p>

        <p>
          <a href="/#/speech-and-language-therapists">Speech & Language Therapists</a>
        </p>

        <p>
          <a href="/#/early-years">Early Years Professionals</a>
        </p>

        <p className={`h4 ${styles.h4}`} style={{ fontWeight: "bold", marginTop: 30 }}>
          Links
        </p>
        <p>
          <a href="/#/">Home</a>
        </p>

        <p>
          <a href="/#/about">About</a>
        </p>
        <p>
          <a href="/#/terms">Terms</a>
        </p>
      </div>

      <div className={styles.footerSection}>
        <p className={`h4 ${styles.h4}`} style={{ fontWeight: "bold" }}>
          Get Started
        </p>
        <p>
          <a
            id="google-link-footer"
            href="https://play.google.com/store/apps/details?id=com.mysunshine.pippin"
            target="_blank"
          >
            Android App
          </a>
        </p>
        <p>
          <a
            id="apple-link-footer"
            href="https://apps.apple.com/us/app/pippin-speech/id1621523957"
            target="_blank"
          >
            iOS App
          </a>
        </p>
      </div>
    </section>
  );
}
