import "../styles/global.scss";
import mockup from "../images/parentLearning.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import mockupPhone from "../images/mockup-vertical.png";
import ratingBubble from "../images/ratingBubble.png";
import checklist from "../images/checklist.png";
import idea from "../images/idea.png";
import Feature from "../components/feature";
import Footer from "../components/footer";
import React, { useEffect } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import pippinLogo from "../images/pippin-logo-parrot.png";
import googleIcon from "../images/google-play.svg";
import appleIcon from "../images/apple-black.svg";
import activitiesHorizontalPreview from "../images/activities.png";
import activitiesHorizontalSmallPreview from "../images/activitiesMobile.png";

function triggerDownloadInterest() {
  if (window?.fbq) {
    // Used by Meta ads to measure conversion
    // Standard events:
    // https://www.facebook.com/business/help/402791146561655?id=1205376682832142
    window.fbq("track", "AddToCart");
  }

  if (document.referrer) {
    axios.post("https://pippinspeech.com/user/website/downloadInterest", {
      miwn: "82hjd",
      referrer: document.referrer,
    });
  }
}

function Home() {
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Landing">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
            <img src={mockup} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0 }}>
            Helping Your Child Find Their Voice
          </h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 15 }}>
            Strategies, activities and resources created by speech therapists.
          </h2>

          <div className={styles.downloadGap}>
            <a
              id="apple-link"
              href="https://apps.apple.com/us/app/pippin-speech/id1621523957"
              target="_blank"
              style={{ margin: "0px 6px" }}
              onClick={triggerDownloadInterest}
              className={"no-underline"}
            >
              <div
                className="button buttonOutlineBlack"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ marginRight: "10px" }}>
                  <img src={appleIcon} loading="lazy" height="22" alt="" />
                </span>
                <span>App Store</span>
              </div>
            </a>
            <a
              id="google-link"
              href="https://play.google.com/store/apps/details?id=com.mysunshine.pippin"
              target="_blank"
              style={{ margin: "0px 6px" }}
              onClick={triggerDownloadInterest}
              className={"no-underline"}
            >
              <div
                className="button buttonOutlineBlack"
                style={{ display: "flex", alignItems: "center" }}
              >
                <span style={{ marginRight: "10px" }}>
                  <img src={googleIcon} loading="lazy" height="22" alt="" />
                </span>
                <span>Play Store</span>
              </div>
            </a>
          </div>
        </div>
      </div>

      <Feature imagePath={mockupPhone} reverse={false} imageLarge={true}>
        <h3>What is Pippin?</h3>
        <p>
          Pippin is an app designed for parents & caregivers (including Early Years Practitioners)
          of children aged 0-5 years old. Pippin gives parents the tools they need to support and
          develop their child’s speech & communication skills. With an online course to help parents
          understand how and why strategies work, and loads of practical activities & games to make
          it easy to put the strategies into practice, Pippin is designed to help parents help their
          child find their voice.
        </p>

        <p>
          Pippin also has age adjusted screening assessment and tracking tools so that each child’s
          progress can be monitored in real time. This information can then be shared with your
          childcare provider, GP, Health Visitor, other family members and your Speech and Language
          Therapist should you need additional support.
        </p>
      </Feature>

      <Feature imagePath={checklist} reverse={true} imageLarge={false}>
        <h3>Speech Assessment</h3>
        <p>
          Our app includes an age adjusted screening assessment and other speech tracking tools,
          allowing you to monitor progress in real-time. The report can optionally be shared
          directly with your childcare provider, GP, Health Visitor, other family members or Speech
          and Language Therapist should you need additional support.
        </p>
      </Feature>

      <Feature imagePath={progress} reverse={false}>
        <h3>Track Progress</h3>
        <p>
          Children are constantly developing; they are regularly learning new skills such as new
          words and gestures. Keep an accurate record and track the development as it happens with
          our gesture and word tracker tools.
        </p>

        <p>
          You can also easily export our Speech Report, providing a handy list of words and gestures
          to share with anyone who supports your child, like a childcare provider or Speech &
          Language Therapist.
        </p>
      </Feature>

      <Feature imagePath={chat} reverse={true}>
        <h3>Activities &amp; Games</h3>
        <p>
          We've created a brilliant collection of activities aimed at encouraging and developing
          your child's speech. Pippin includes a selection of songs, games and other activities for
          you and your child can enjoy!
        </p>

        <p>
          Activities are added and updated regularly by speech therapists, providing you with even
          more fun and engaging things to try at home.
        </p>

        {innerWidth > 800 && (
          <img src={activitiesHorizontalPreview} style={{ marginTop: 40, maxWidth: "100%" }} />
        )}

        {innerWidth < 800 && (
          <img src={activitiesHorizontalSmallPreview} style={{ marginTop: 30, maxWidth: "100%" }} />
        )}
      </Feature>

      <Feature imagePath={idea} reverse={false}>
        <h3>Your Questions Answered</h3>
        <p>
          Sometimes you've just got a question and you would love an answer without being on long
          waiting lists. Pippin Premium offers monthly live Q&A sessions so you can have a chat with
          our resident Speech & Language Therapist, Kate, and get the answers you need.
        </p>
      </Feature>

      <Feature imagePath={ratingBubble} reverse={true}>
        <h3>What do parents say about Pippin?</h3>
        <p>
          “[My son] is making steady progress as a result of the strategies we use. It's also
          completely transformed my confidence in how to support my child, am reassured that I am
          supporting him in the best way I can.”
        </p>

        <p>
          “Your course is simply brilliant, it is so parent friendly it feels like a friend giving
          supportive tips”
        </p>

        <p>“[The course is] having a great impact”</p>

        <p>“[It's] very helpful”.</p>
      </Feature>

      <Footer />
    </div>
  );
}

export default Home;
